import React, { useState } from "react";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";
import { AffiliateDeal, CompanyTemplateData, Support } from "../Company/data";

import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import * as cardClasses from "../../components/ArticleCard/style.module.css";
import { Icon } from "../../components/Icon";
import * as socialClasses from "../../components/SocialProfiles/style.module.css";
import * as articleClasses from "../../templates/Article/style.module.css";
import * as classes from "./style.module.css";
import AdSense from 'react-adsense';

interface CompanyListingTemplateProps {
  pageContext: {
    company: CompanyTemplateData;
  };
}

interface FilterOption {
  label: string;
  selected: boolean;
  relatedCompanyIds: string[];
}

const commonButtonStyle = {
  padding: "0.5rem 1.25rem",
  marginTop: "0.5rem",
  marginBottom: "1rem",
  marginRight: "auto",
  marginLeft: "auto",
  width: "90%",
};

Modal.setAppElement("#___gatsby");

export default function CompanyTemplate(
  props: CompanyListingTemplateProps
): React.ReactElement {
  const [modalShow, setModalShow] = useState(false);
  const [currentlySelectedDeal, setCurrentlySelectedDeal] = useState("");
  const companies = props.pageContext.company;
  const twitter = 'https://twitter.com/';
  const facebook = 'https://facebook.com/';
  const instagram = 'https://instagram.com/' + companies.instagram;
  const articleCard = (
    <article className={cardClasses.IndividualCard}>
      <div>
        {companies.banner && companies.banner.src ? (
          <GatsbyImage
            className={cardClasses.IndividualImageWrapper}
            imgClassName={cardClasses.Image}
            image={companies.banner.src.childImageSharp.gatsbyImageData}
            alt={companies.banner.alt || companies.name}
            style={{ marginTop: "1rem" }}
          />
        ) : null}
        <div className={cardClasses.DescriptionWrapper}>
          <span className={cardClasses.Category}>
            <u>{companies.name}</u>
          </span>
        </div>
      </div>

      <div className={cardClasses.DescriptionWrapper}>
        <h4 className={cardClasses.Title}>{companies.brief}</h4>
        <div className={cardClasses.Details}>
          <span className={classes.ReadingTime}>
            Based in {companies.city}, {companies.country}
          </span>
          <br />
          <span className={classes.ReadingTime}>
            Founded by {companies.founder}
          </span>
        </div>

        <a
          className={socialClasses.Profile}
          href={companies.website}
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label={"profile.label"}
          style={{
            padding: "0.5rem 1.25rem",
            marginTop: "1rem",
            marginBottom: "1rem",
            marginRight: "auto",
            marginLeft: "auto",
            width: "90%",
          }}
        >
          {<Icon name={"external"} />} {"Website"}
        </a>
      </div>
    </article>
  );

  const shouldNotShowSocialCard = () => {
    const instagram = companies.instagram === "none";
    const twitter = companies.twitter === "none";
    const facebook = companies.facebook === "none";
    const youtube = companies.youtube === "none";
    const result = instagram && twitter && facebook && youtube;
    return result;
  }

  const generateSocialCard = () => {
    const getLink = (name: string, color: string, link: string) => {
      return (
        <a
          className={socialClasses.Profile}
          href={link}
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label={"profile.label"}
          style={commonButtonStyle}
        >
          {<Icon name={name} color={color} />} {name}
        </a>
      );
    };

    return (
      <React.Fragment>
        <article className={cardClasses.IndividualCard}>
          <div className={cardClasses.DescriptionWrapper}>
            {companies.instagram !== 'none'
              ? getLink("Instagram", "#fb3958", instagram)
              : null}
            {companies.twitter !== 'none' ? getLink("Twitter", "#1DA1F2", twitter + companies.twitter) : null}
            {companies.facebook !== 'none'
              ? getLink("Facebook", "#4267B2", facebook + companies.facebook)
              : null}
            {companies.youtube && companies.youtube?.length > 5 ? getLink("Youtube", "#FF0000", companies.youtube) : null}
          </div>
        </article>
      </React.Fragment>
    );
  };

  const generateAllSupportCards = () => {
    const shouldShowContent = companies.support.some(
      (x) => x.supportTitle === "none"
    );
    const allSupport = companies.support.map((support) => {
      return generateSupportCard(support);
    });
    const emptyContentMessage = (
      <h3>
        {companies.name} has no ways of supporting them at the moment &#128546;
      </h3>
    );
    return !shouldShowContent ? allSupport : emptyContentMessage;
  };

  const generateSupportCard = (support: Support) => {
    return (
      <article className={cardClasses.SupportCard}>
        <div className={cardClasses.DescriptionWrapper}>
          <h4 className={cardClasses.Title} style={{ marginTop: "0rem" }}>
            {support.supportTitle}
          </h4>
          <div className={cardClasses.Details}>
            <span className={classes.ReadingTime}>{support.supportDetail}</span>
          </div>
          <a
            className={socialClasses.Profile}
            href={support.supportLink} // add url from mdx
            target="_blank"
            rel="nofollow noopener noreferrer"
            aria-label={"profile.label"}
            style={commonButtonStyle}
          >
            {<Icon name={"external"} />} {"Find out more"}
          </a>
        </div>
      </article>
    );
  };

  const generateAllDealCards = () => {
    const shouldShowContent = companies.deals.some(
      (x) => x.dealTitle === "none"
    );
    const allDeals = companies.deals.map((deal) => {
      return generateDealCard(deal);
    });
    const emptyContentMessage = (
      <h3>
        {companies.name} has no deals currently listed. Drop a{" "}
        <a
          href={companies.instagram && companies.instagram?.length > 4 ? instagram : companies.website}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          DM!
        </a>{" "}
        &#128521;
      </h3>
    );
    return !shouldShowContent ? allDeals : emptyContentMessage;
  };

  const onOpen = () => {
    const test = document.getElementById("___gatsby");
    if (test) {
      test.style.opacity = "0.5";
    }
  };

  const onClose = () => {
    const test = document.getElementById("___gatsby");
    if (test) {
      test.style.opacity = "1";
    }
  };

  const getModalContents = () => {
    const deals = companies.deals;
    const deal = deals.find((x) => x.dealTitle === currentlySelectedDeal);
    const isDealCode = deal && !!(deal.dealCode === "none");
    const modalContents = !isDealCode ? (
      <div>
        <div>
          <p>1. Click copy code & visit website</p>
          <p>2. The brand website will open in a new tab</p>
          <p>3. Enter the code at checkout</p>
        </div>
        <div>
          <button className={socialClasses.buttonCode}>
            <p>{deal?.dealCode} </p>
          </button>
          <a
            className={socialClasses.Profile}
            href={companies.website} // add url from mdx
            target="_blank"
            rel="nofollow noopener noreferrer"
            aria-label={"profile.label"}
            style={{ ...commonButtonStyle, width: "100%", textAlign: "center" }}
          >
            {<Icon name={"external"} />} {"Copy Code and Visit Website"}
          </a>
        </div>
      </div>
    ) : (
      <div>
        <div>
          <p>1. Click visit website</p>
          <p>2. The company website will open in a new tab</p>
          <p>3. The discount code will already be applied</p>
        </div>
        <a
          className={socialClasses.Profile}
          href={deal.dealLink}
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label={"profile.label"}
          style={{ ...commonButtonStyle, width: "100%", textAlign: "center" }}
        >
          {<Icon name={"external"} />} {"Visit Website"}
        </a>
      </div>
    );
    return modalContents;
  };

  const generateDealCard = (deal: AffiliateDeal) => {
    return (
      <>
        <article className={cardClasses.SupportCard}>
          <div className={cardClasses.DescriptionWrapper}>
            <h4 className={cardClasses.Title} style={{ marginTop: "0rem" }}>
              {deal.dealTitle}
            </h4>
            <div className={cardClasses.Details}>
              <span className={classes.ReadingTime}>{deal.dealDetail}</span>
            </div>
            <a
              className={socialClasses.Profile}
              rel="nofollow noopener noreferrer"
              aria-label={"profile.label"}
              style={commonButtonStyle}
              onClick={() => {
                setModalShow(true);
                setCurrentlySelectedDeal(deal.dealTitle);
              }}
            >
              {<Icon name={"external"} />} {"View Deal"}
            </a>
          </div>
        </article>
      </>
    );
  };

  return (
    <>
      <Seo title={companies.name} useTitleTemplate={true} />
      <Page>
        <Section
          anchor="company"
          heading=""
          additionalClasses={classes.CompanyPageWrapper}
        >
          <div className={articleClasses.Breadcrumb}>
            <Link to={"/companies"} title="Back To Companies">
              <span className={articleClasses.BackArrow}>&#10094;</span>
              All Companies
            </Link>
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.item2}>{articleCard}</div>
            <div className={classes.item3}>{!shouldNotShowSocialCard() ? generateSocialCard() : null}</div>
            <div className={classes.item4}>
              {!companies.support.some((x) => x.supportTitle === "none") ? (
                <div>
                  <h3 style={{ margin: "1rem 0 1rem 0" }}>
                    Best ways to support {companies.name}
                  </h3>
                </div>
              ) : null}
              {generateAllSupportCards()}
            </div>
            <div className={classes.item5}>
              {!companies.deals.some((x) => x.dealTitle === "none") ? (
                <div>
                  <h3 style={{ margin: "1rem 0 1rem 0" }}>Latest Deals</h3>
                </div>
              ) : null}
              {generateAllDealCards()}
            </div>
          </div>
          {modalShow ? (
            <Modal
              isOpen={modalShow}
              onRequestClose={() => {
                setModalShow(false);
              }}
              contentLabel={`Deal Modal`}
              className={socialClasses.mymodal}
              overlayClassName={socialClasses.myoverlay}
              closeTimeoutMS={500}
              preventScroll
              shouldCloseOnEsc
              onAfterOpen={() => {
                onOpen();
              }}
              onAfterClose={() => {
                onClose();
              }}
              //set opacity and background color to low and grey when opened
              // reverse when closed
            >
              <div>
                <button
                  onClick={() => {
                    setModalShow(false);
                  }}
                  style={{ marginLeft: "auto", display: "block" }}
                  className={socialClasses.buttonClose}
                >
                  Close
                </button>
                {/* <h4 className={cardClasses.Title} style={{ marginTop: "0rem" }}>
              {deal.dealTitle}
            </h4> */}
                {getModalContents()}
              </div>
            </Modal>
          ) : null}
          <div>
            <AdSense.Google
              client="ca-pub-9176200361619120"
              slot="8260019779"
              style={{ display: "block" }}
              format="fluid"
            />
          </div>
        </Section>
      </Page>
    </>
  );
}
